// make elements overlap each other
$color-button-about: #53ac5d;
$color-button-donate: #53ac5d;
$color-hover: #75d780;
$route-color: #b5f7c2;

.overlap {
  display: grid;
  grid-template-columns: 1fr;
  .children {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 50%;
    margin: 0 auto;
  }
}

.bigger {
  padding: 1rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(16px, 2.3vmin, 1.7rem);
  font-weight: 400;
  color: #000;
}

.medium {
  padding: 1rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(16px, 2.3vmin, 2rem);
  font-weight: 400;
  color: #000;
}

.caption {
  padding: 1rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(16px, 1vmin, 1rem);
  font-weight: 400;
  color: #000;
}

.supporter-list {
  display: block;
  max-height: 600px; /* or any other value */
  overflow-y: scroll;
  scrollbar-width: thin; /* hide scrollbar except in WebKit-based browsers */

}

.supporter-list::-webkit-scrollbar {
  width: 0.5rem; /* width of scrollbar */
}

.supporter-list::-webkit-scrollbar-thumb {
  background-color: #888; /* color of scrollbar thumb */
  border-radius: 0.25rem; /* roundness of scrollbar thumb */
}

.supporter-list::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* color of scrollbar track */
  border-radius: 0.25rem; /* roundness of scrollbar track */
}

.supporter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  margin: 10px;
  border-radius: 10px;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 0.5rem;
  }
  p {
    margin: 0;
    font-size: clasp(16px, 4vmin, 1rem);
    @media (max-width: 768px) {
      font-size: 0.6rem !important;
    }
  }
}

.supporter-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 0.5rem;
  }
  margin: 10px;
  border-radius: 10px;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
  text-align: center;
  p {
    margin: 0;
    font-size: clasp(16px, 5vmin, 2rem);
    @media (max-width: 768px) {
      font-size: 0.6 !important;
    }
  }
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: rgba(228, 231, 63, 0.418);
  &:after {
    background: #fff;
    content: "";
    height: 255px;
    left: -130px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    width: 50px;
    z-index: -10;

    /* CSS animation */
    animation: slide-right 4s ease-in-out infinite;
  }
}

.emphasis-supporter {
  font-weight: 700;
  color: #4ad227;
}

.emphasis-supporter-top {
  font-weight: 700;
  color: #000000;
}

.odd {
  background-color: #f0f0f0;
}

.even {
  background-color: #ddf5dd;
}

.about-us-button {
  text-align: center;
  padding: 1rem;
  background-color: $color-button-about;
  color: #fff;
  border-radius: 10px;
  font-size: clasp(16px, 4vmin, 1rem);
  font-weight: 700;
  border-color: $color-button-about;
  &:hover {
    background-color: $color-hover;
    border-color: $color-hover;
    color: #fff;
  }
}

.donate-button {
  text-align: center;
  padding: 3rem;
  background-color: $color-button-donate;
  border-color: $color-button-donate;
  color: #fff;
  border-radius: 10px;
  font-size: clasp(16px, 20vmin, 5rem);
  font-weight: 700;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 1);
  &:hover {
    background-color: $color-hover;
    border-color: $color-hover;
    color: #fff;
  }
  &:after {
    background: #fff;
    content: "";
    height: 255px;
    left: -130px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    width: 50px;
    z-index: -10;

    /* CSS animation */
    animation: slide-right 2s ease-in-out infinite;
  }
}

/* Define the animation */
@keyframes slide-right {
  0% {
    left: -130px;
  }
  100% {
    left: 120%;
  }
}

// make this invisible when behind an element

.flag-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); // Bootstrap's light gray background color
  z-index: 1;
  .flag {
    cursor: pointer;
    width: 40px;
    height: 30px;
    // make it smaller on mobile
    @media (max-width: 768px) {
      width: 30px;
      height: 20px;
    }
    margin: 0 10px;
  }
}
