@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;600;700;800;900&family=Roboto:wght@600&display=swap");

$dark: #0f3f49;
$medium: #4e7d55;
$light: #f2f2f2;
$light1: #8ebc61;
$light2: #00BB31;

html {
  height: 100% !important;
}

.App {
  position: relative;
  background-image: url("./Resources/Pics/background.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.btn-primary-green {
  background-color: $light2 !important;
  border-color: $light2 !important;
  color: black !important;
  font-weight: 500 !important;
  &:hover {
    background-color: $light1 !important;
    border-color: $light1 !important;
  }
  padding: 2rem;
  margin: 1rem;
}

.section-title {
  text-align: center;
  text-transform: uppercase;
  padding: 2rem;
  margin: 1rem;
  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: clamp(16px, 6vmin, 100px);
  }
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: clamp(12px, 4vmin, 100px);
  }
}

.simple-section {
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 1.5rem;
  &-header h1 {
    padding: 1rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: clamp(16px, 6vmin, 3rem);
    font-weight: 700;
    color: #000;
  }
  &-header p {
    padding: 1rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(16px, 4vmin, 2rem);
    font-weight: 400;
    color: #000;
    padding: 2rem;
  }
  &-body {
    padding: 1rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(16px, 2vmin, 2rem);
    font-weight: 400;
    color: #000;
    padding: 2rem;

    h2 {
      font-family: "Montserrat", sans-serif;
      font-size: clamp(20px, 4vmin, 2.5rem);
      font-weight: 600;
      color: #000;
    }
  }
}

.emphasis {
  color: $light2;
  font-weight: 600;
}

a:link {
  text-decoration: none;
}

.link-to-page {
  color: $light1;
  &:hover {
    color: $light2 !important;
  }
}

@import "~bootstrap/scss/bootstrap";
