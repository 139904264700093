@import "../../custom";
$color-hover: #75d780;
$color-button: #53ac5d;
.blurry {
  backdrop-filter: blur(12px) saturate(100%);
  background-color: rgba(245, 244, 241, 0.25);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  min-height: 5vh;
  padding: 0.5rem;
}

.align-it {
  align-items: center !important;
  color: black;
  .main {
    &:hover {
      transform: scale(1.1);
    }
  }

  $margin-button: 0.2rem;
  .button-menu {
    background-color: $color-button;
    color: $black;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.35rem;
    border-radius: 0.5rem;
    border: none;
    margin-left: $margin-button;
    margin-right: $margin-button;
    cursor: pointer;
    &:hover {
      background-color: $color-hover;
      border-color: $color-hover;
      color: #fff;
    }
    &:focus {
      outline: none;
    }

  }


  .hydrogen {
    background-color: #365ea8;
    color: $white;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.25rem;
    border-radius: 0.5rem;
    border: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: #233263;
      color: $white;
    }
  }
}

.navbar-align2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 3rem;
}

.dropItem {
  color: black !important;

  &:hover {
    color: black !important;
    font-weight: 700;
  }
}
.dropdown-item {
  color: black !important;
  &:hover {
    color: black !important;
    background-color: $light !important;
    font-weight: 700;
  }
}

.main-aux {
  color: #00bb31;
  font-weight: bold;
  &:hover {
    transform: scale(1.1);
  }
}
